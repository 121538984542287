import { useEffect, useState } from 'react';
import usePostMsgContext from '@apps/containers/WebviewProvider/hooks';
import { dateConversions } from '@apps/utils/date-conversion';
import avatarPlaceholder from '@stack/assets/images/avatar_placeholder.jpeg';
import { Badge } from '@stack/ui/components';
import classNames from 'classnames';
import Link from 'next/link';

import StyledImage from '../Image';
import StyledSkeleton from '../Skeleton';

import { ContentProps } from './props';
import {
  AuthorWrap,
  AvatarWrap,
  ContentBody,
  ContentFooter,
  ContentSkeletonWrapper,
  ContentWrapper,
  StyledAuthorText,
  StyledDescription,
  StyledFooterText,
  StyledIcon,
  StyledTitle,
  StyledTitleTag,
  TitleSkeletonWrapper,
} from './styles';

export const Contents: React.FC<ContentProps> = (props) => {
  // ======================= PROPS
  const {
    title,
    // titleSlug,
    titleClass,
    titleTag,
    description,
    headerText,
    footerText,
    badgeLabel,
    enableTitlePointer = false,
    enableWhiteText = false,
    simpleMbView = false,
    author,
    authorAvatar,
    authorEmail,
    titleLine = 3,
    isList = false,
    descriptionLine = 2,
    footerTextJustify = 'space-between',
    href = '/',
    hideDescription = false,
    loading = false,
    webView = false,
    authorImageProps,
  } = props;
  const [days, setDays] = useState(footerText);

  useEffect(() => {
    const newDays = dateConversions(footerText);
    if (newDays) {
      setDays(newDays);
    }
  }, [footerText]);

  const { clickEvent } = usePostMsgContext();
  if (loading)
    return (
      <ContentSkeletonWrapper $simpleMbView={simpleMbView} className="content">
        <ContentBody $isList={isList}>
          <StyledTitle
            $whiteText={enableWhiteText}
            $simpleMbView={simpleMbView}
            $titleLine={titleLine}
            $isList={isList}
            style={{ minWidth: '100%', margin: 0 }}
          >
            <TitleSkeletonWrapper $simpleMbView={simpleMbView}>
              <div
                className="skeleton skeleton-title-text"
                style={{
                  minWidth: '100%',
                }}
              />
              <div
                className="skeleton skeleton-title-text"
                style={{
                  minWidth: '100%',
                }}
              />
              <div
                className="skeleton skeleton-title-text"
                style={{
                  minWidth: '100%',
                }}
              />
            </TitleSkeletonWrapper>
          </StyledTitle>
          <StyledDescription
            $whiteText={enableWhiteText}
            $simpleMbView={simpleMbView}
            $descriptionLine={descriptionLine}
            $isList={isList}
          >
            <div
              className="skeleton skeleton-description-text"
              style={{
                minWidth: '100%',
              }}
            />
            <div
              className="skeleton skeleton-description-text"
              style={{
                minWidth: '100%',
              }}
            />
            <div
              className="skeleton skeleton-description-text"
              style={{
                minWidth: '100%',
              }}
            />
          </StyledDescription>
          <ContentFooter $justifyContent={footerTextJustify}>
            <AuthorWrap
              style={{
                minWidth: '50%',
              }}
            >
              <AvatarWrap>
                <div
                  className={classNames('display-none', {
                    'display-block overlay skeleton': loading,
                  })}
                  style={{
                    borderRadius: '50px',
                  }}
                />
              </AvatarWrap>

              <StyledAuthorText
                style={{
                  minWidth: '30%',
                }}
              >
                <div
                  className="skeleton skeleton-title-text"
                  style={{
                    minWidth: '100%',
                    margin: 0,
                  }}
                />
              </StyledAuthorText>
            </AuthorWrap>
            <StyledFooterText
              style={{
                minWidth: '30%',
              }}
            >
              <div
                className="skeleton skeleton-title-text "
                style={{
                  minWidth: '100%',
                  margin: 0,
                }}
              />
            </StyledFooterText>
          </ContentFooter>
        </ContentBody>
      </ContentSkeletonWrapper>
    );

  // ======================= VIEWS
  return (
    <ContentWrapper className="content">
      {badgeLabel && (
        <div>
          <Badge label={badgeLabel} />
        </div>
      )}
      {headerText && (
        <ContentFooter>
          <StyledFooterText $whiteText={enableWhiteText}>
            {headerText}
          </StyledFooterText>
          <StyledIcon className="material-symbols-outlined">
            bookmark
          </StyledIcon>
        </ContentFooter>
      )}
      <ContentBody $isList={isList}>
        <Link className={'url-ani-2'} href={href} onClick={clickEvent(href)}>
          <StyledTitle
            className={classNames('url-ani-2', {
              [`${titleClass}`]: !!titleClass,
              'text-xl': !titleClass,
            })}
            $whiteText={enableWhiteText}
            $simpleMbView={simpleMbView}
            $titleLine={titleLine}
            $isList={isList}
            $loading={loading}
          >
            {enableTitlePointer && (
              <StyledIcon className="material-symbols-outlined">
                <span className="material-symbols-outlined">trending_flat</span>
              </StyledIcon>
            )}{' '}
            {titleTag && <StyledTitleTag>{titleTag}:</StyledTitleTag>}
            <StyledSkeleton text={title} title={true} loading={loading} />
          </StyledTitle>
        </Link>
        {description && (
          <StyledDescription
            $whiteText={enableWhiteText}
            $simpleMbView={simpleMbView}
            $descriptionLine={descriptionLine}
            $hideDescription={hideDescription}
            $isList={isList}
            $loading={loading}
          >
            <StyledSkeleton
              text={description}
              description={true}
              loading={loading}
            />
          </StyledDescription>
        )}
        {footerText && (
          <ContentFooter $justifyContent={footerTextJustify}>
            <AuthorWrap>
              {
                <AvatarWrap>
                  <div
                    className={classNames('display-none', {
                      // 'display-block loader': !loading,
                    })}
                  />
                  <div
                    className={classNames('display-none', {
                      'display-block overlay skeleton': loading,
                    })}
                  />
                  <StyledImage
                    src={authorAvatar || avatarPlaceholder.src}
                    width={24}
                    height={24}
                    imageStyle={{
                      borderRadius: '50px',
                      display: loading ? 'none' : 'block',
                    }}
                    {...authorImageProps}
                  />
                </AvatarWrap>
              }
              {author && (
                <Link
                  href={
                    webView
                      ? `/webview/author/${authorEmail?.toLowerCase()}`
                      : `/author/${authorEmail?.toLowerCase()}`
                  }
                >
                  <StyledAuthorText
                    className="url-ani-2"
                    $whiteText={enableWhiteText}
                  >
                    <StyledSkeleton
                      text={author}
                      description={true}
                      loading={loading}
                    />
                  </StyledAuthorText>
                </Link>
              )}
            </AuthorWrap>
            <StyledFooterText $whiteText={enableWhiteText}>
              <StyledSkeleton
                text={days}
                description={true}
                loading={loading}
              />
            </StyledFooterText>
          </ContentFooter>
        )}
      </ContentBody>
    </ContentWrapper>
  );
};

// ======================= EXPORT
export * from './props';
export default Contents;
